import { render, staticRenderFns } from "./iframe.vue?vue&type=template&id=1366de36&"
import script from "./iframe.vue?vue&type=script&lang=ts&"
export * from "./iframe.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IframeLayout: require('/opt/atlassian/pipelines/agent/build/components/layouts/IframeLayout.vue').default,BaseLayout: require('/opt/atlassian/pipelines/agent/build/components/layouts/BaseLayout.vue').default})
