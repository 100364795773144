import { render, staticRenderFns } from "./default.vue?vue&type=template&id=f0d4ea10&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DefaultLayout: require('/opt/atlassian/pipelines/agent/build/components/layouts/DefaultLayout.vue').default,BaseLayout: require('/opt/atlassian/pipelines/agent/build/components/layouts/BaseLayout.vue').default})
