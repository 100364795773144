import { render, staticRenderFns } from "./PModal.vue?vue&type=template&id=b4cab4fa&"
import script from "./PModal.vue?vue&type=script&lang=ts&"
export * from "./PModal.vue?vue&type=script&lang=ts&"
import style0 from "./PModal.vue?vue&type=style&index=0&id=b4cab4fa&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PIcon: require('/opt/atlassian/pipelines/agent/build/components/PIcon.vue').default,PButton: require('/opt/atlassian/pipelines/agent/build/components/PButton.vue').default,PSubmitBar: require('/opt/atlassian/pipelines/agent/build/components/PSubmitBar.vue').default})
